import React from "react";

export default function FilterButton({
  filter,
  color,
  title,
  count,
  onClick,
  textColor = "black",
}) {
  return (
    <div style={{ display: "flex", flexDirection: "column" }}>
      <button
        disabled={
          window.location.href.includes(`status=${filter}`) ||
          window.location.href.includes(`date=${filter}`)
        }
        style={
          window.location.href.includes(
            `status=${filter}` ||
              window.location.href.includes(`date=${filter}`)
          )
            ? {
                backgroundColor: color,
                border: "1px solid #000",
                whiteSpace: "nowrap",
                minWidth: "100px",
                width: "fit-content",
                color: textColor,
              }
            : {
                backgroundColor: color,
                whiteSpace: "nowrap",
                minWidth: "100px",
                width: "fit-content",
                color: textColor,
              }
        }
        value={filter}
        onClick={onClick}
      >
        {title}
        {<br />}
        {count}
      </button>
    </div>
  );
}
