import React from "react";

import DeleteIcon from "@mui/icons-material/Delete";

export default function DeleteAction() {
  return (
    <>
      <DeleteIcon
        style={{ color: "rgba(214, 36, 47)", marginRight: 8 }}
        fontSize="0.9rem"
      />
      <span
        style={{
          color: "rgba(214, 36, 47)",
          fontSize: "0.9rem",
          fontFamily: "SeagoeUIBold",
        }}
      >
        Remove
      </span>
    </>
  );
}
