import React from "react";

import { MenuItem } from "@mui/material";

export default function ActionCustomTable({ options }) {
  return options.map((option, key) => (
    <MenuItem key={key} onClick={() => option.onClick()}>
      {option.component}
    </MenuItem>
  ));
}
