import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogTitle from "@mui/material/DialogTitle";
import { LinearProgress, CircularProgress } from "@mui/material";

export default function AlertModal({
  text,
  open,
  handleClose,
  handleYes,
  showYesButton = true,
  showNoButton = true,
  maxWidth = "md",
  loading = false,
}) {
  return (
    <React.Fragment>
      <Dialog
        open={open}
        onClose={handleClose}
        maxWidth={maxWidth}
        fullWidth
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        {loading ? (
          <div className="loading-icon">
            <CircularProgress color="inherit" size={25} />
          </div>
        ) : (
          <>
            <DialogTitle
              style={{
                // display: "flex",
                // justifyContent: "center",
                fontSize: "1.2rem",
                fontFamily: "SeagoeUI",
              }}
              id="alert-dialog-title"
            >
              <pre>{text}</pre>
            </DialogTitle>
            <DialogActions>
              {showYesButton && <Button onClick={handleYes}>Yes</Button>}
              {showNoButton && (
                <Button onClick={handleClose} autoFocus>
                  No
                </Button>
              )}
            </DialogActions>
          </>
        )}
      </Dialog>
    </React.Fragment>
  );
}
