import React from "react";

import PreviewIcon from "@mui/icons-material/Preview";

export default function ViewDeclaration({ title = "View Declaration", type }) {
  return (
    <>
      <PreviewIcon
        style={{ color: "rgba(33, 43, 54, 0.9)", marginRight: 8 }}
        fontSize="0.9rem"
      />
      <span
        style={{
          color: "rgba(33, 43, 54, 0.9)",
          fontSize: "0.9rem",
          fontFamily: "SeagoeUIBold",
        }}
      >
        {title}
      </span>
    </>
  );
}
