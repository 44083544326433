import fetcher from "../helpers/fetcher";

export async function ReqMazdaExport(page, officeID, searchTerm, status) {
  let path = `/export-declarations/mazda/total?page=${page}&office_id=${officeID}&search=${searchTerm}&status=${status}`;

  let request;
  try {
    request = await fetcher(path, "GET");
  } catch (error) {
    throw error;
  }

  let content = request.json();

  return content;
}

export async function SendMRNToMazda(id, mrn) {
  let path = `/export-declarations/mazda/send_mrn/${id}?mrn=${mrn}`;

  let req = await fetcher(path, "GET");

  const response = req.json();

  return response;
}

export async function GetMazdaDeclarationInfo(id, email) {
  let path = `/export-declarations/mazda/info?id=${id}&email=${email}`;

  let request;
  try {
    request = await fetcher(path, "GET");
  } catch (error) {
    throw error;
  }

  let content = request.json();

  return content;
}

export async function UpdateMazdaDeclarationInfo(data, id, office) {
  let path = `/export-declarations/mazda/update_data/${id}?office_id=${office}`;

  let headers = {
    "Content-Type": "application/json",
  };

  let response = await fetcher(path, "PUT", data, headers);

  return response;
}

export async function SubmitMazdaDeclarationData(data, id, office) {
  let path = `/export-declarations/mazda/submit/${id}?office_id=${office}`;

  let headers = {
    "Content-Type": "application/json",
  };

  let response = await fetcher(path, "POST", data, headers);

  return response;
}

export async function DeleteMazdaDeclaration(id) {
  let path = `/export-declarations/mazda/${id}`;

  let response = await fetcher(path, "DELETE");

  let content = response.json();

  return content;
}

export async function DownloadFile(url) {
  let path = `/export-declarations/mazda/download_file?url=${url}`;

  let request = await fetcher(path, "GET");

  const content = await request.blob();
  const pdfUrl = window.URL.createObjectURL(content);

  let tempLink = document.createElement("a");
  tempLink.href = pdfUrl;

  let fileName = url.split("/")[url.split("/").length - 1];

  tempLink.setAttribute("download", fileName);
  tempLink.click();

  return content;
}

export async function DownloadMazdaXmls(id, lrn) {
  let path = `/export-declarations/mazda/download_xml/${id}`;

  let request = await fetcher(path, "GET");

  const content = await request.blob();
  const csvURL = window.URL.createObjectURL(content);

  let tempLink = document.createElement("a");
  tempLink.href = csvURL;
  tempLink.setAttribute("download", `${lrn}.xml`);
  tempLink.click();

  return content;
}

export async function SendLiberVama(data, id) {
  let path = `/export-declarations/mazda/send_liber_vama/${id}`;

  let req = await fetcher(path, "POST", data, true, true);

  const response = req.json();

  return response;
}
