import React, { useState, useEffect, useRef } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import moment from "moment";

// Services
import {
  DeleteMazdaDeclaration,
  ReqMazdaExport,
  DownloadMazdaXmls,
  SendMRNToMazda,
  SendLiberVama,
  DownloadFile,
} from "../../../services/mazda";

// Components
import CustomTable from "../../../components/table";
import ActionCustomTable from "../../../components/table/action";
import ViewDeclaration from "../../../components/table/actions/view-declaration";
import DeleteAction from "../../../components/table/actions/delete";
import DownloadAction from "../../../components/table/actions/download";
import AlertModal from "../../../components/modal/alert";
import FilterButton from "../../../components/filterbutton";
import PopSnackbar from "../../../components/snackbar/Snackbar";

// Hooks
import useDebounce from "../../../hooks/debounce";

// MUI Components
import { Tooltip, Menu, IconButton, TextField } from "@mui/material";

// Icons
import MoreVertIcon from "@mui/icons-material/MoreVert";
import EditIcon from "@mui/icons-material/Edit";
import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";
import { Edit } from "react-feather";

export default function CreateExportMazda({ selectedOfficeID }) {
  // === State Hooks ===
  const [data, setData] = useState([]);
  const [page, setPage] = useState(0);
  const [totalCount, setTotalCount] = useState(0);
  const [inputText, setInputText] = useState("");
  const [tableLoading, setTableLoading] = useState(false);
  const [toastStatus, setToastStatus] = useState(false);
  const [description, setDescription] = useState("");

  const [menuAnchor, setMenuAnchor] = useState(null);
  const [selectedData, setSelectedData] = useState("");

  const [open, setOpen] = useState(false);
  const [openMessage, setOpenMessage] = useState(false);

  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(false);

  const [editableMrn, setEditableMrn] = useState({});
  const [isEditing, setIsEditing] = useState({});
  const [originalMrn, setOriginalMrn] = useState({});
  const [mrnAlert, setMrnAlert] = useState(false);
  const [selectedRow, setSelectedRow] = useState(null);
  const [loadingMRN, setLoadingMRN] = useState(false);

  const [lastRefreshed, setLastRefreshed] = useState(null);
  const [filterCount, setFilterCount] = useState({
    pending: 0,
    error: 0,
    success: 0,
    generated_xml: 0,
  });

  // === Refs ===
  const intervalRef = useRef(null);
  const fileInputRef = useRef(null);

  // === Hooks ===
  const debouncedSearchTerm = useDebounce(inputText, 500);
  const navigate = useNavigate();
  const location = useLocation();
  const status = new URLSearchParams(location.search).get("status");
  const date = new URLSearchParams(location.search).get("date");

  // === Table Columns Definition ===
  const columns = [
    {
      field: "id",
      headerName: "ID",
      headerAlign: "center",
      sortable: false,
    },
    {
      field: "created_at",
      headerName: "Created At",
      headerAlign: "center",
      sortable: false,
    },
    {
      field: "lrn",
      headerName: "LRN",
      headerAlign: "center",
      sortable: false,
    },
    {
      field: "mrn",
      headerName: "MRN",
      headerAlign: "center",
      sortable: false,
      renderCell: (params) => {
        const isEditable = params.row.status === "generated_xml";
        const isEditingRow = isEditing[params.row.id];

        return (
          <div style={{ display: "flex", alignItems: "center" }}>
            {isEditable && isEditingRow ? (
              <TextField
                variant="outlined"
                size="small"
                value={(editableMrn[params.row.id] ?? params.row.mrn) || ""}
                onChange={(e) => handleMrnChange(params.row.id, e.target.value)}
                style={{ marginRight: 8, minWidth: 80 }}
              />
            ) : (
              <span>
                {editableMrn[params.row.id] || params.row.mrn || "TBD"}
              </span>
            )}
            {isEditable &&
              (isEditingRow ? (
                <>
                  <IconButton
                    onClick={() => {
                      setMrnAlert(true);
                      setSelectedRow(params.row);
                      // sendMrn(params.row.id, editableMrn[params.row.id]);
                    }}
                    size="small"
                  >
                    <CheckIcon fontSize="small" />
                  </IconButton>
                  <IconButton
                    onClick={() => cancelEdit(params.row.id)}
                    size="small"
                  >
                    <CloseIcon fontSize="small" />
                  </IconButton>
                </>
              ) : (
                <IconButton
                  onClick={() => startEditing(params.row.id, params.row.mrn)}
                  size="small"
                >
                  <EditIcon fontSize="small" />
                </IconButton>
              ))}
          </div>
        );
      },
    },
    {
      field: "created_at",
      headerName: "Craeted By and When",
      headerAlign: "center",
      sortable: false,
      renderCell: (params) => {
        return (
          <div>
            <div>
              {params.row.mazda_created_at && (
                <span
                  style={{
                    fontSize: 14,
                    fontFamily: "SeagoeUI",
                  }}
                >
                  Mazda:{" "}
                  {moment(params.row.mazda_created_at)
                    .utc()
                    .format("DD-MM-YYYY HH:mm")}
                </span>
              )}
            </div>
            <div>
              <span
                style={{
                  fontSize: 14,
                  fontFamily: "SeagoeUI",
                }}
              >
                TCS: {params.row.created_at}
              </span>
            </div>
          </div>
        );
      },
    },
    {
      field: "status",
      headerName: "Status",
      headerAlign: "center",
      sortable: false,
      renderCell: (params) => {
        return switchStatus(params.row.status);
      },
    },
    {
      field: "liber_de_vama_pdf",
      headerName: "Liber de Vama",
      headerAlign: "center",
      sortable: false,
      renderCell: (params) => {
        if (params.row.status === "success" && params.row.liber_de_vama_pdf) {
          return (
            <div
              style={{ cursor: "pointer" }}
              onClick={() => DownloadFile(params.row.liber_de_vama_pdf)}
            >
              <p style={{ textDecorationLine: "underline" }}>
                preview liber de vama
              </p>
            </div>
          );
        } else if (params.row.status === "success") {
          return (
            <div
              style={{ cursor: "pointer" }}
              onClick={() => handleUploadLiberVamaClick(params.row)}
            >
              <p style={{ textDecorationLine: "underline" }}>
                încarcă liber de vama
              </p>
            </div>
          );
        } else {
          return <p>N/A</p>;
        }
      },
    },
    {
      field: "preluat_de",
      headerName: "Preluat de",
      headerAlign: "center",
      sortable: false,
    },
    {
      field: "actions",
      headerName: "Actions",
      headerAlign: "center",
      sortable: false,
      renderCell: (params) => {
        return (
          <div style={{ display: "flex", alignItems: "center" }}>
            {["pending", "error"].includes(params.row.status) && (
              <Tooltip title="Edit declaration">
                <Edit
                  style={{ cursor: "pointer", color: "gray" }}
                  onClick={() => {
                    navigate(`/mazda_export/${params.row.id}`);
                  }}
                ></Edit>
              </Tooltip>
            )}
            <IconButton
              aria-label="actions"
              onClick={(e) => handleClickAnch(e, params.row)}
              size="small"
            >
              <MoreVertIcon fontSize="small" />
            </IconButton>
            <Menu
              id={`actions-menu`}
              anchorEl={menuAnchor}
              open={
                selectedData.id === params.row.id ? Boolean(menuAnchor) : false
              }
              onClose={handleCloseAnch}
            >
              <ActionCustomTable
                options={[
                  {
                    component: <ViewDeclaration />,
                    onClick: () =>
                      navigate(`/mazda_export/${params.row.id}`, {
                        state: { preview: true },
                      }),
                  },
                  params.row.xml_path != "" && {
                    component: <DownloadAction />,
                    onClick: () =>
                      DownloadXml(selectedData.id, selectedData.lrn),
                  },
                  {
                    component: <DeleteAction />,
                    onClick: () => setOpen(true),
                  },
                ].filter(Boolean)}
              />
            </Menu>
          </div>
        );
      },
    },
  ];
  // === Helper Functions ===
  const handleStatus = (description, setStatus, setDescription) => {
    setStatus(true);
    setDescription(description);
    setTimeout(() => {
      setStatus(false);
      setDescription("");
    }, 5000);
  };

  const clearIntervalIfExists = () => {
    if (intervalRef.current) {
      clearInterval(intervalRef.current);
      intervalRef.current = null;
    }
  };

  const switchStatus = (status) => {
    switch (status) {
      case "pending":
        return <div className="pending-status">pending</div>;
      case "error":
        return <div className="error-status">error</div>;
      case "success":
        return <div className="success-status">success</div>;
      case "generated_xml":
        return <div className="awaiting-status">generated xml</div>;
      default:
        return <div className="unknown-status">{status}</div>;
    }
  };

  const handleClickAnch = (event, row) => {
    setMenuAnchor(event.currentTarget);
    setSelectedData(row);
  };

  const handleCloseAnch = () => {
    setMenuAnchor(null);
  };

  const handleClose = (isMessage) => {
    setSelectedData("");
    if (isMessage) {
      setOpenMessage(false);
    } else {
      setOpen(false);
    }
  };

  const startEditing = (id, currentMrn) => {
    setOriginalMrn((prev) => ({ ...prev, [id]: currentMrn }));
    setEditableMrn((prev) => ({ ...prev, [id]: currentMrn }));
    setIsEditing((prev) => ({ ...prev, [id]: true }));
  };

  const cancelEdit = (id) => {
    setEditableMrn((prev) => ({ ...prev, [id]: originalMrn[id] }));
    setIsEditing((prev) => ({ ...prev, [id]: false }));
  };

  const handleMrnChange = (id, value) => {
    setEditableMrn((prev) => ({ ...prev, [id]: value }));
  };

  const handleUploadLiberVamaClick = (row) => {
    setSelectedRow(row); // salvăm rândul curent
    fileInputRef.current.click(); // deschide file picker
  };

  // === API Functions ===
  const sendMrn = async (id, value) => {
    try {
      setLoadingMRN(true);
      const response = await SendMRNToMazda(id, value);
      if (response.status !== "OK")
        throw new Error(response.error || "Something went wrong.");

      if (response.mazda_response.status !== "success") {
        handleStatus(
          "A aparut o eroare la trimiterea MRN-ului!",
          setToastStatus,
          setDescription
        );
      } else {
        await getData(0, false);
      }
    } catch (error) {
      handleStatus(error.message, setToastStatus, setDescription);
    } finally {
      setIsEditing((prev) => ({ ...prev, [id]: false }));
      setMrnAlert(false);
      setLoadingMRN(false);
    }
  };

  const DownloadXml = async (id, lrn) => {
    try {
      await DownloadMazdaXmls(id, lrn);
      handleStatus("File downloaded successfully", setSuccess, setDescription);
    } catch {
      handleStatus("Download failed.", setError, setDescription);
    } finally {
      setMenuAnchor(null);
    }
  };

  const handleDelete = async () => {
    try {
      setTableLoading(true);
      handleClose(false);
      const content = await DeleteMazdaDeclaration(
        selectedData.id,
        selectedOfficeID
      );
      if (content.status !== "OK")
        throw new Error(content.error || "Delete failed.");
      await getData(0, false);
    } catch (error) {
      handleStatus(error.message, setToastStatus, setDescription);
    } finally {
      setTableLoading(false);
      setMenuAnchor(null);
    }
  };

  const handleLiberVamaFileChange = async (event) => {
    const file = event.target.files[0];
    if (!file) return;

    if (file.type !== "application/pdf") {
      handleStatus(
        "Doar fișiere PDF sunt acceptate.",
        setError,
        setDescription
      );
      return;
    }

    try {
      const formData = new FormData();
      formData.append("file", file);

      const response = await SendLiberVama(formData, selectedRow?.id);

      if (response.status !== "OK") {
        throw new Error(response.error || "Upload failed.");
      }

      handleStatus(
        "Liber de Vamă încărcat cu succes.",
        setSuccess,
        setDescription
      );
      await getData(page, false, debouncedSearchTerm, status);
    } catch (err) {
      handleStatus(err.message, setError, setDescription);
    } finally {
      event.target.value = null; // reset input
    }
  };

  const getData = async (page, isLoading = true, searchTerm = "", status) => {
    try {
      isLoading && setTableLoading(true);
      const response = await ReqMazdaExport(
        page + 1,
        selectedOfficeID,
        searchTerm,
        status
      );

      if (response.page === 1) setTotalCount(response.totalCount);

      const transformed = response.data.map((item) => ({
        id: item.ID,
        created_at: moment(item.CreatedAt).format("DD-MM-YYYY HH:mm"),
        lrn: item.lrn,
        mrn: item.mrn,
        status: item.status,
        preluat_de: item.preluat_de,
        mazda_created_at: item.mazda_created_at,
        xml_path: item.xml_path,
        liber_de_vama_pdf: item.liber_de_vama_pdf,
      }));

      setData(transformed);
      setFilterCount(response.count);
      setLastRefreshed(new Date());
    } catch (error) {
      handleStatus(error.message, setToastStatus, setDescription);
    } finally {
      isLoading && setTableLoading(false);
    }
  };

  const handleChangePage = async (event, newPage) => {
    clearIntervalIfExists();
    await getData(newPage, true, debouncedSearchTerm, status, date);
    setPage(newPage);
  };

  // === Effects ===
  useEffect(() => {
    if (debouncedSearchTerm.length >= 3 || debouncedSearchTerm.length === 0) {
      setPage(0);
      getData(0, true, debouncedSearchTerm, status, date);
    }
  }, [debouncedSearchTerm, status, date]);

  useEffect(() => {
    const interval = setInterval(() => {
      getData(page, false, debouncedSearchTerm, status);
    }, 7500);

    return () => clearInterval(interval);
  }, [page, debouncedSearchTerm, status, selectedOfficeID]);

  // === JSX Return ===
  return (
    <div style={{ display: "flex", flexDirection: "column" }}>
      {lastRefreshed && (
        <p
          style={{
            fontFamily: "SeagoeUIItalic",
            alignSelf: "flex-end",
            marginBottom: "0.7vw",
          }}
        >
          Last Refreshed: {moment(lastRefreshed).format("HH:mm:ss")}
        </p>
      )}

      <div
        style={{
          display: "flex",
          cursor: "pointer",
          fontFamily: "SeagoeUIBold",
          fontSize: "0.9em",
          alignSelf: "flex-end",
          textDecorationLine: "underline",
          marginBottom: "0.7vw",
        }}
        onClick={() => navigate("")}
      >
        clear filters
      </div>

      {toastStatus && <PopSnackbar message={description} />}

      <div className="filter-btns">
        {["success", "pending", "error", "generated_xml"].map((type) => (
          <FilterButton
            key={type}
            filter={type}
            color={
              {
                success: "#B4F5A9",
                pending: "#F7F7AD",
                error: "#F7ADAD",
                generated_xml: "#EE82EE",
              }[type]
            }
            title={type
              .replace("_", " ")
              .replace(/\b\w/g, (char) => char.toUpperCase())}
            count={filterCount[type]}
            onClick={() => navigate(`?status=${type}`)}
          />
        ))}

        <AlertModal
          open={open}
          handleClose={() => handleClose(false)}
          handleYes={handleDelete}
          text="Are you sure you want to delete this declaration?"
        />

        <AlertModal
          open={mrnAlert}
          handleClose={() => setMrnAlert(false)}
          handleYes={() =>
            sendMrn(selectedRow?.id, editableMrn[selectedRow?.id])
          }
          text={`Are you sure you want to update the MRN to ${
            editableMrn[selectedRow?.id]
          } for LRN ${selectedRow?.lrn}?`}
          loading={loadingMRN}
        />
      </div>

      <input
        type="file"
        accept="application/pdf"
        ref={fileInputRef}
        style={{ display: "none" }}
        onChange={handleLiberVamaFileChange}
      />

      <CustomTable
        search
        inputText={inputText}
        setInputText={setInputText}
        columns={columns}
        tableLoading={tableLoading}
        data={data}
        page={page}
        handleChangePage={handleChangePage}
        totalCount={totalCount}
      />
    </div>
  );
}
